import {
	DateTimePicker,
	Dropzone,
	IconHeader,
	Modal,
	Popover,
	Select,
	SlidePanel,
	Switch,
	Table,
	Tabs,
	createSelectOnChange,
	toast,
	useConfirm,
} from '@touchpoints/ui'
import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'

import { CandidateAIChat } from '@components/candidates/CandidateAIChat'
import { CandidateActivityTab } from '@components/candidates/CandidateEvents'
import { CandidateEmailsTab } from '@components/candidates/CandidatePanel'
import { CandidateSequences } from '@components/candidates/CandidateSequences'
import { CandidateStageTimeline } from '@components/candidates/CandidateStageTimeline'
import { PositionCandidateStage } from '@components/candidates/PositionCandidateStage'
import { PositionCandidateCommentsPanel } from '@components/comments/CommentsPanel'
import { PositionCandidateInfo } from '@components/positions/candidates/PositionCandidateInfo'
import { AssigneeSelect } from '@components/shared/AssigneeSelect'
import { TaskList } from '@components/tasks/TaskList'
import { useReaction } from '@hooks/mobx'
import { useDecay } from '@hooks/useDecay'
import { Button, Loading, Text, Tooltip } from '@nextui-org/react'
import { candidateAttachmentUrl, positionCandidateAttachmentUrl } from '@requests/attachments'
import { formatTaskDate, formatTaskTime } from '@services/date'
import { orgUserNameDisplay } from '@services/format'
import { rootStore } from '@store'
import {
	ICandidate,
	ICandidateAttachment,
	IPositionCandidate,
	IPositionCandidateAttachment,
	IPositionCandidateResumeNote,
	ISequence,
	ISequenceInstance,
	PositionCandidateScheduledEventProps,
} from '@touchpoints/requests'
import { add, sub } from 'date-fns'
import { Checkbox, Label } from 'flowbite-react'
import { reaction } from 'mobx'
import { useEffect, useMemo, useState } from 'react'
import { FaRegComment } from 'react-icons/fa'
import {
	HiCheckCircle,
	HiOutlineCalendar,
	HiOutlineCheckCircle,
	HiPencil,
	HiPlus,
	HiStar,
	HiTrash,
} from 'react-icons/hi'
import ReactMarkdown from 'react-markdown'
import { useTimeout, useWindowSize } from 'usehooks-ts'
import { ActionItem } from './ActionItems'
import { BoardViewWrapper } from './BoardView'
import { CandidateLabel } from './CandidateLabel'
import { CardDetailedViewColumn } from './CardDetailedViewColumn'
import { EventsRow } from './EventsRow'
import { PositionLabel } from './PositionLabel'
import { store } from './store'
import { formatDate } from '@services/time'

enum TabsType {
	Main = 'main',
	Chat = 'chat',
	Candidate = 'candidate',
	Sequences = 'sequences',
	Actions = 'actions',
	Tasks = 'tasks',
	Activity = 'activity',
	Events = 'events',
	Attachments = 'attachements',
	Emails = 'emails',
	ResumeNotes = 'resume-notes',
	Edit = 'edit',
	Similars = 'similars',
}

type CardDetailedViewProps = {
	mode?: 'modal' | 'slide'
	onClose?: () => void
	sidebar?: boolean
}
export const CardDetailedView = observer(function ({
	mode = 'slide',
	onClose,
	sidebar = true,
}: CardDetailedViewProps) {
	const router = useRouter()
	const open = store.isOpen
	const winSize = useWindowSize()

	const { c } = router.query as { c: string }

	useEffect(() => {
		return reaction(
			() => store.isOpen,
			(isOpen) => {
				if (!rootStore.accounts.activeAccountId) {
					return
				}

				if (isOpen) {
					router.replace(
						`/accounts/${rootStore.accounts.activeAccountId}/board/${store.activePositionCandidateId}`
					)
				} else {
					router.replace(`/accounts/${rootStore.accounts.activeAccountId}/board`)
				}
			}
		)
	}, [router])

	useEffect(() => {
		return store.onClose(() => {
			// remove query param from url
			const params = router.query ?? {}
			delete params.c
			router.push(
				{
					query: {
						...params,
					},
				},
				undefined,
				{ shallow: true }
			)
		})
	}, [router])

	const width = winSize.width <= 1024 ? winSize.width * 0.95 : winSize.width * 0.8

	if (mode === 'modal') {
		return (
			<Modal
				isOpen={open}
				onClose={() => {
					store.close()
					onClose?.()
				}}
				width={`${width}px`}
				className="!bg-gray-200"
			>
				<Modal.Body
					css={{
						height: `${winSize.height * 0.9}px`,
						paddingLeft: 0,
						paddingRight: 0,
						paddingTop: 0,
						paddingBottom: 0,
					}}
				>
					<CardDetailedPanel
						positionCandidateId={store.activePositionCandidateId || c}
						open={open}
						sidebar={sidebar}
					/>
				</Modal.Body>
			</Modal>
		)
	}

	return (
		<SlidePanel
			isOpen={open}
			onRequestClose={() => store.close()}
			width={`${width}px`}
			hideHeader
			panelContenClassName="p-0 overflow-hidden"
		>
			<CardDetailedPanel
				positionCandidateId={store.activePositionCandidateId}
				open={open}
				sidebar={sidebar}
			/>
		</SlidePanel>
	)
})

type CardDetailsPanelProps = {
	positionCandidateId: string
	open: boolean
	sidebar?: boolean
}
export const CardDetailedPanel = observer(function ({
	positionCandidateId,
	open,
	sidebar = true,
}: CardDetailsPanelProps) {
	const router = useRouter()
	const [tab, setTab] = useState(TabsType.Main)
	const [_rerenderCount, setRerenderCount] = useState(0)

	const positionCandidate =
		rootStore.positions.getPositionCandidateById(positionCandidateId) ??
		// NOTE: this is a fallback if the given positionCandidateId is actually a candidateId
		rootStore.positions.getPositionCandidatesForCandidate(positionCandidateId)[0]

	const position = positionCandidate
		? rootStore.positions.getPositionById(positionCandidate.positionId)
		: undefined

	const recruiter = positionCandidate?.recruiterId
		? rootStore.organizationUsers.users[positionCandidate.recruiterId]
		: undefined

	const { next: nextDelay, reset: resetDelay } = useDecay(1000, {
		maxCount: 100,
		maxValue: 30000,
		rate: 1.05,
	})
	const [delay, setDelay] = useState<number | null>(null)
	const [refreshing, setRefreshing] = useState(false)
	const [candidate, setCandidate] = useState<ICandidate | undefined>(undefined)

	const [loading, setLoading] = useState(true)

	const orgId = rootStore.organizations.activeOrganizationId

	useEffect(() => {
		rootStore.recent.addPositionCandidateId(positionCandidateId)
	}, [positionCandidateId])

	useEffect(() => {
		if (!positionCandidate) {
			return
		}

		// get active instances
		rootStore.sequences.fetchSequenceInstancesForPositionCandidate(positionCandidate.id)
	}, [positionCandidate])

	const activeSequence = useReaction(
		() => {
			if (!positionCandidate) {
				return
			}

			const list = rootStore.sequences
				.getSequenceInstancesByCandidateAndPosition(
					positionCandidate.candidateId,
					positionCandidate.positionId
				)
				.map((instance) => {
					return {
						instance,
						sequence: rootStore.sequences.getSequenceById(instance.sequenceId),
					}
				})
				.filter((d) => !!d.sequence)

			return list.find((d) => d.instance?.status === 'running') as
				| {
						instance: ISequenceInstance
						sequence: ISequence
				  }
				| undefined
		},
		100,
		[positionCandidate]
	)

	useEffect(() => {
		if (positionCandidate || !orgId) {
			return
		}

		// fetch the position candidate if we don't have it already
		rootStore.positions
			.fetchPositionCandidateById(positionCandidateId, { priority: 1 })
			.then(() => {
				setRerenderCount((c) => c + 1)
			})
	}, [positionCandidate, positionCandidateId, orgId])

	useEffect(() => {
		setLoading(!positionCandidate && rootStore.positions.isLoading)
	}, [positionCandidate])

	// NOTE: this is a fallback if the given positionCandidateId is actually a candidateId
	// we'll just look for the best match positionCandidateId; may not work well if candidate
	// is in multiple positions in the same account (likely won't be the case)
	useEffect(() => {
		const accountId = rootStore.accounts.activeAccountId
		// don't do this if not looking at a specific account
		if (!accountId) {
			return
		}

		async function action() {
			let pc = rootStore.positions.getPositionCandidateById(positionCandidateId)
			if (!pc) {
				const candidate = await rootStore.candidates.getCandidateByIdAsync(
					positionCandidateId
				)
				if (candidate) {
					await rootStore.positions.fetchPositionCandidatesForCandidate(candidate.id)
					const positions = rootStore.positions.getPositionCandidatesForCandidate(
						candidate.id
					)
					pc = positions
						.filter((pc) => {
							const position = rootStore.positions.getPositionById(pc.positionId)
							if (position?.status !== 'open') {
								return false
							}

							return position?.accountId === rootStore.accounts.activeAccountId
						})
						.reverse()[0]
				}
			}
			if (pc) {
				const path = `/accounts/${accountId}/board/${pc.id}`
				if (router.asPath !== path) {
					router.replace(path, undefined, {
						shallow: true,
					})
				}
			}
		}
		action()
	}, [positionCandidateId, router])

	useEffect(() => {
		if (!positionCandidate) {
			return
		}

		async function action() {
			const positionId = positionCandidate?.positionId
			if (!positionId) return

			rootStore.positions.setActivePositionId(positionCandidate?.positionId)
		}
		action()
	}, [positionCandidate])

	const handleChangeAssignee = async (assigneeId: string) => {
		if (!positionCandidate) {
			return
		}

		await rootStore.positions.updatePositionCandidate(
			positionCandidate.candidateId,
			positionCandidate.positionId,
			{
				recruiterId: assigneeId,
			}
		)
	}

	useEffect(() => {
		const fetchCandidate = async (candidateId: string) => {
			const candidate = await rootStore.candidates.getCandidateByIdAsync(candidateId, 10)
			setCandidate(candidate)
		}

		if (positionCandidate) {
			fetchCandidate(positionCandidate.candidateId)
		}
	}, [positionCandidate])

	useEffect(() => {
		if (!open) {
			store.setSelectedPositionCandidateId('')
		}
	}, [open])

	useEffect(() => {
		setDelay(resetDelay())
	}, [resetDelay, open])

	useTimeout(
		() => {
			if (!positionCandidate) {
				return
			}

			setRefreshing(true)
			rootStore.positions
				.fetchPositionCandidate(
					positionCandidate.positionId,
					positionCandidate.candidateId,
					{
						priority: 10,
						force: true,
					}
				)
				.then(() => {
					setDelay(nextDelay())
					setRefreshing(false)
				})
		},
		positionCandidate ? delay : null
	)

	if (loading) {
		return (
			<div className="flex justify-center items-center w-full h-full overflow-x-hidden">
				<Loading size="lg" />
			</div>
		)
	}

	if (!positionCandidate) {
		return (
			<div className="flex justify-center items-center w-full h-full overflow-x-hidden">
				<Loading size="lg" />
			</div>
		)
	}

	return (
		<div className="flex w-full h-full border overflow-x-clip overflow-y-hidden overscroll-x-none">
			{sidebar && (
				<CardDetailedViewColumn
					positionCandidate={positionCandidate}
					loadingCandidates={loading}
				/>
			)}
			<div className="flex flex-col space-y-1 w-full h-full p-5 overflow-x-hidden">
				<div className="flex flex-col space-y-1 w-full flex-initial px-2">
					<CandidateLabel
						candidate={candidate}
						refreshing={refreshing}
						activeSequence={activeSequence}
						onSequenceClick={() => setTab(TabsType.Sequences)}
					/>
					<PositionLabel
						position={position}
						candidate={candidate}
						positionCandidate={positionCandidate}
						positionCandidateId={positionCandidateId}
					/>

					{((candidate && position) || positionCandidate) && (
						<CandidateStageTimeline
							candidateId={candidate?.id ?? positionCandidate.candidateId}
							positionId={position?.id ?? positionCandidate.positionId}
							canExpand={true}
						/>
					)}
					<div className="flex space-x-3 py-2">
						<div className="flex flex-col w-1/2">
							<AssigneeSelect
								assignee={recruiter?.id}
								onChange={handleChangeAssignee}
							/>
						</div>
						{(candidate || positionCandidate) && (
							<div className="flex flex-col w-1/2">
								<PositionCandidateStage
									candidate={candidate ?? positionCandidate.candidateId}
									positionId={position?.id ?? positionCandidate.positionId}
									onChange={() => {
										setDelay(resetDelay())
									}}
								/>
							</div>
						)}
					</div>
				</div>
				<div className="flex flex-grow">
					<Tabs value={tab} onValueChange={(v) => setTab(v as TabsType)}>
						<Tabs.Bar className="">
							<Tabs.Item value={TabsType.Main} className="!bg-transparent">
								Overview
							</Tabs.Item>
							<Tabs.Item value={TabsType.Chat} className="!bg-transparent">
								Chat
							</Tabs.Item>
							<Tabs.Item value={TabsType.ResumeNotes} className="!bg-transparent">
								<p className="whitespace-nowrap">Resume Notes</p>
							</Tabs.Item>
							<Tabs.Item value={TabsType.Activity} className="!bg-transparent">
								Activity
							</Tabs.Item>
							<Tabs.Item value={TabsType.Events} className="!bg-transparent">
								Events
							</Tabs.Item>
							<Tabs.Item value={TabsType.Attachments} className="!bg-transparent">
								Attachments
							</Tabs.Item>
							<Tabs.Item value={TabsType.Sequences} className="!bg-transparent">
								Sequences
							</Tabs.Item>
							<Tabs.Item value={TabsType.Tasks} className="!bg-transparent">
								Tasks
							</Tabs.Item>
							<Tabs.Item value={TabsType.Actions} className="!bg-transparent">
								Actions
							</Tabs.Item>
							<Tabs.Item value={TabsType.Emails} className="!bg-transparent">
								Emails
							</Tabs.Item>
							<Tabs.Item value={TabsType.Similars} className="!bg-transparent">
								Similars
							</Tabs.Item>
							{position && candidate && (
								<Tabs.Item value={TabsType.Edit} className="!bg-transparent">
									<HiPencil size="1.25em" />
								</Tabs.Item>
							)}
						</Tabs.Bar>
						<Tabs.Content
							value={TabsType.Main}
							className="h-96 overflow-y-scroll !bg-transparent"
						>
							<div className="flex flex-col space-y-8 ">
								<Events positionCandidateId={positionCandidateId} />
								{positionCandidate?.actionItems
									?.filter((item) => item.status === 'active')
									.map((item) => (
										<ActionItem
											key={item.id}
											positionCandidate={positionCandidate}
											item={item}
											onComplete={() => {
												setDelay(resetDelay())
											}}
										/>
									))}
								<Comments positionCandidateId={positionCandidateId} />
							</div>
						</Tabs.Content>
						<Tabs.Content
							value={TabsType.Chat}
							className="h-96 overflow-y-scroll !bg-transparent"
						>
							<CandidateAIChat />
						</Tabs.Content>
						<Tabs.Content
							value={TabsType.Sequences}
							className="h-96 overflow-y-scroll !bg-transparent"
						>
							{candidate && (
								<CandidateSequences
									candidateId={candidate.id}
									positionId={position?.id}
									canAddToSequence={false}
								/>
							)}
						</Tabs.Content>
						<Tabs.Content
							value={TabsType.Actions}
							className="h-96 overflow-y-scroll !bg-transparent"
						>
							<ActionsPanel />
						</Tabs.Content>
						<Tabs.Content
							value={TabsType.Tasks}
							className="h-96 overflow-y-scroll !bg-transparent"
						>
							<TasksPanel />
						</Tabs.Content>
						{candidate?.id && tab === TabsType.Activity && (
							<CandidateActivityTab
								tabValue={TabsType.Activity}
								candidateId={candidate.id}
							/>
						)}
						<Tabs.Content
							value={TabsType.Events}
							className="h-96 overflow-y-scroll !bg-transparent"
						>
							<EventsPanel />
						</Tabs.Content>
						<Tabs.Content
							value={TabsType.Attachments}
							className="h-96 overflow-y-scroll !bg-transparent"
						>
							<AttachmentsPanel />
						</Tabs.Content>
						{position && candidate && (
							<Tabs.Content
								value={TabsType.Edit}
								className="h-96 overflow-y-scroll !bg-transparent"
							>
								<EditPositionCandidateTab
									positionId={position.id}
									candidateId={candidate.id}
								/>
							</Tabs.Content>
						)}
						<Tabs.Content
							value={TabsType.Emails}
							className="h-96 overflow-y-scroll !bg-transparent"
						>
							{candidate && <CandidateEmailsTab candidateId={candidate.id} />}
						</Tabs.Content>
						<Tabs.Content
							value={TabsType.Similars}
							className="h-96 overflow-y-scroll !bg-transparent"
						>
							<Similars positionCandidateId={positionCandidateId} />
						</Tabs.Content>
						<Tabs.Content
							value={TabsType.ResumeNotes}
							className="h-96 overflow-y-scroll !bg-transparent"
						>
							<ResumeNotes positionCandidateId={positionCandidateId} />
						</Tabs.Content>
					</Tabs>
				</div>
			</div>
		</div>
	)
})

type CommentsProps = {
	positionCandidateId: string
}
function Comments({ positionCandidateId }: CommentsProps) {
	const [showSystem, setShowSystem] = useState(false)
	const [showActivity, setShowActivity] = useState(false)

	return (
		<div className="flex flex-col space-y-2 my-2 ">
			<div className="flex items-center space-x-2 my-1">
				<div className="w-6 h-6 p-1 bg-[#FEEEF8] rounded-md">
					<FaRegComment className="text-[#D6409F]" />
				</div>
				<div className="text-md font-medium leading-6">Comments</div>
			</div>

			<div className="flex items-center justify-end space-x-2 pl-5 pr-7">
				<div className="flex space-x-2 items-center">
					<Checkbox checked={showSystem} onChange={() => setShowSystem(!showSystem)} />
					<Label className="flex">Show System</Label>
				</div>
				<div className="flex space-x-2 items-center">
					<Checkbox
						checked={showActivity}
						onChange={() => setShowActivity(!showActivity)}
					/>
					<Label className="flex">Show Activity</Label>
				</div>
			</div>
			<PositionCandidateCommentsPanel
				positionCandidateId={positionCandidateId}
				showActivity={showActivity}
				showSystem={showSystem}
			/>
		</div>
	)
}

type PositionCandidateInfoProps = {
	candidateId: string
	positionId: string
}
function EditPositionCandidateTab({ candidateId, positionId }: PositionCandidateInfoProps) {
	const [positionCandidate, setPositionCandidate] = useState<Partial<IPositionCandidate>>()
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (!candidateId || !positionId) {
			return
		}

		async function getPositionCandidate() {
			await rootStore.positions.fetchPositionCandidate(positionId, candidateId, {
				priority: 5,
				force: true,
			})

			const pc = rootStore.positions.getPositionCandidate(candidateId, positionId)
			setPositionCandidate({ ...pc })
		}

		getPositionCandidate()
	}, [candidateId, positionId])

	const onFieldChange = (field: keyof IPositionCandidate, value: any) => {
		setPositionCandidate((pc) => ({ ...pc, [field]: value }))
	}

	const handleUpdate = async () => {
		if (!positionCandidate) {
			return
		}

		const { candidateId, positionId } = positionCandidate
		if (!candidateId || !positionId) {
			return
		}

		setLoading(true)
		await rootStore.positions.updatePositionCandidate(candidateId, positionId, {
			accountExecutiveId: positionCandidate.accountExecutiveId,
			engagementContactId: positionCandidate.engagementContactId,
			recruiterId: positionCandidate.recruiterId,
			supportContactId: positionCandidate.supportContactId,
			trelloCardUrl: positionCandidate.trelloCardUrl,
		})
		setLoading(false)
	}

	return (
		<PositionCandidateInfo
			label="Position Information"
			positionCandidate={positionCandidate}
			onFieldChange={onFieldChange}
		>
			{positionCandidate && (
				<div className="flex justify-end">
					<Button auto onClick={handleUpdate} disabled={loading}>
						{loading ? <Loading /> : 'Update'}
					</Button>
				</div>
			)}
		</PositionCandidateInfo>
	)
}

function TasksPanel() {
	const positionCandidate = useReaction(() => {
		return rootStore.positions.getPositionCandidateById(store.activePositionCandidateId)
	})

	return (
		<div className="flex flex-col space-y-3 my-1">
			<IconHeader
				icon={<HiOutlineCheckCircle className="text-[#0091FF]" />}
				message="Related tasks"
			/>

			{positionCandidate?.candidateId && (
				<TaskList
					candidateId={positionCandidate?.candidateId}
					collapsable={false}
					avatarSize={30}
				/>
			)}
		</div>
	)
}

function ActionsPanel() {
	const positionCandidate = useReaction(() => {
		const positionCandidate = rootStore.positions.getPositionCandidateById(
			store.activePositionCandidateId
		)
		return positionCandidate
	})

	const events = useReaction(
		() => {
			if (!positionCandidate) {
				return []
			}

			const allEvents = rootStore.candidates.events.getEventsForCandidate(
				positionCandidate.candidateId
			)
			return [...allEvents].sort((a, b) => {
				return b.ts - a.ts
			})
		},
		100,
		[positionCandidate]
	)

	useEffect(() => {
		if (!positionCandidate) {
			return
		}

		rootStore.candidates.events.fetchAllEventsForCandidate(positionCandidate.candidateId)
	}, [positionCandidate])

	if (!positionCandidate) {
		return null
	}

	const completedActions =
		positionCandidate?.actionItems
			?.filter((a) => a.status === 'completed')
			.map((a) => ({ ...a }))
			.sort((a, b) => (a.completedAt ?? 0) - (b.completedAt ?? 0))
			.reverse() ?? []

	return (
		<div className="flex flex-col space-y-3">
			{completedActions.length <= 0 && (
				<div className="flex items-center justify-center h-32">
					<p className="text-base text-slate-500">No completed actions</p>
				</div>
			)}
			{completedActions.map((a) => {
				const evt = events.find((e) => e.payload?.actionItemId === a.id)
				const { source, orgUserId } = (evt?.payload ?? {}) as {
					source?: string
					orgUserId?: string
				}
				const orgUser =
					source === 'user' && orgUserId
						? rootStore.organizationUsers.users[orgUserId]
						: undefined
				return (
					<div key={a.id} className="flex flex-col pb-10 w-full">
						{a.completedAt && (
							<div className="flex items-center space-x-3 text-base font-semibold">
								<HiCheckCircle size={20} className="text-emerald-500" />
								&nbsp;Completed on {formatTaskDate(a.completedAt)}{' '}
								<span className="text-sm font-normal">
									at {formatTaskTime(a.completedAt)}
									{orgUser ? ` by ${orgUserNameDisplay(orgUser)}` : ''}
								</span>
							</div>
						)}
						<div className="ml-4">
							<ActionItem positionCandidate={positionCandidate} item={a} readOnly />
						</div>
					</div>
				)
			})}
		</div>
	)
}

function useScheduledEvents() {
	const now = Date.now()
	const msInAWeek = 1000 * 3600 * 24 * 7
	const lastWeek = now - msInAWeek

	const positionCandidate = useReaction(() => {
		const positionCandidate = rootStore.positions.getPositionCandidateById(
			store.activePositionCandidateId
		)
		return positionCandidate
	})

	const scheduledEvents =
		positionCandidate?.scheduledEvents
			?.map((e) => ({ ...e }))
			.filter((e) => !e.time || (e.time && e.time > now))
			.sort((a, b) => {
				if (!a.time) {
					return -1
				}

				if (!b.time) {
					return 1
				}

				return a.time - b.time
			}) ?? []

	const pastEvents =
		positionCandidate?.scheduledEvents
			?.map((e) => ({ ...e }))
			.filter((e) => e.time && e.time <= now) ?? []

	const recentPastEvents = pastEvents.filter((e) => e.time && e.time > lastWeek)

	return { scheduledEvents, pastEvents, recentPastEvents }
}

function EventsPanel() {
	const positionCandidate = useReaction(() => {
		const positionCandidate = rootStore.positions.getPositionCandidateById(
			store.activePositionCandidateId
		)
		return positionCandidate
	})

	const { scheduledEvents, pastEvents } = useScheduledEvents()

	const [deleting, setDeleting] = useState(false)
	const confirm = useConfirm()

	const handleDeleteEvent = async (id: string) => {
		if (!positionCandidate) {
			return
		}

		const shouldContinue = await confirm({
			title: 'Delete Event',
			message: 'Are you sure you want to delete this event?',
			confirmColor: 'error',
		})

		if (!shouldContinue) {
			return
		}

		const index = scheduledEvents.findIndex((e) => e.id === id)
		const evt = scheduledEvents[index]

		setDeleting(true)
		await rootStore.positions.deleteCandidateEvent(
			positionCandidate.candidateId,
			positionCandidate.positionId,
			evt.id
		)
		setDeleting(false)
	}

	return (
		<div className="flex flex-col space-y-2">
			<CreateEvent />

			{scheduledEvents.length > 0 && (
				<div>
					{' '}
					<p className="w-full text-base font-medium"> Upcoming</p>
					{scheduledEvents.map((evt) => (
						<EventsRow
							key={evt.id}
							event={evt}
							onDelete={handleDeleteEvent}
							deleting={deleting}
						/>
					))}
				</div>
			)}

			{pastEvents.length > 0 && (
				<div>
					<p className="w-full text-base font-medium"> Past Events</p>
					{pastEvents.length > 0 ? (
						pastEvents
							.sort((a, b) => (a.time ?? 0) - (b.time ?? 0))
							.map((evt) => <EventsRow key={evt.id} event={evt} />)
					) : (
						<p className="flex justify-center items-center h-16">
							<span className="text-base text-slate-500">No completed events</span>
						</p>
					)}
				</div>
			)}
		</div>
	)
}

type EventsProps = {
	positionCandidateId?: string
	recentPastEvents?: boolean
}
function Events({ positionCandidateId }: EventsProps) {
	useEffect(() => {
		if (!positionCandidateId) {
			return
		}

		store.setSelectedPositionCandidateId(positionCandidateId)
	}, [positionCandidateId])

	const { scheduledEvents, recentPastEvents } = useScheduledEvents()
	const upcomingEvent = scheduledEvents[0]

	if (!upcomingEvent && !recentPastEvents) {
		return null
	}

	return (
		<div className="flex flex-col">
			{upcomingEvent && (
				<>
					<p className="w-full text-base font-medium"> Upcoming</p>
					<EventsRow event={upcomingEvent} />
				</>
			)}
			{recentPastEvents && recentPastEvents.length > 0 && (
				<>
					<p className="w-full text-base font-medium"> Recent past events</p>
					{recentPastEvents.map((evt) => (
						<EventsRow key={evt.id} event={evt} />
					))}
				</>
			)}
		</div>
	)
}

function CreateEvent() {
	const [selectedDate, setSelectedDate] = useState<Date | null>(new Date())
	const [loading, setLoading] = useState(false)
	const [selectedEventType, setSelectedEventType] = useState('')

	const eventTypes = useReaction(() =>
		rootStore.candidates.settings.evenTypes.map((e) => ({ ...e }))
	)

	const positionCandidate = useReaction(() => {
		const positionCandidate = rootStore.positions.getPositionCandidateById(
			store.activePositionCandidateId
		)
		return positionCandidate
	})

	const scheduledEvents = useReaction(() => {
		const positionCandidate = rootStore.positions.getPositionCandidateById(
			store.activePositionCandidateId
		)
		return positionCandidate?.scheduledEvents?.map((e) => ({ ...e })) ?? []
	})

	const handleAddEvent = async () => {
		if (!positionCandidate) {
			return
		}

		if (!selectedDate) {
			return
		}

		const eventType = eventTypes.find((e) => e.id === selectedEventType)
		if (!eventType) {
			return
		}
		const newEvents: PositionCandidateScheduledEventProps[] = [...scheduledEvents]
		const newEvent = {
			name: eventType.name,
			time: selectedDate?.getTime(),
			eventTypeId: eventType.id,
		}
		newEvents.push(newEvent)

		setLoading(true)
		await rootStore.positions.addCandidateEvent(
			positionCandidate.candidateId,
			positionCandidate.positionId,
			newEvent
		)

		setSelectedEventType('')
		setLoading(false)
	}

	const today = useMemo(() => new Date(), [])
	const minDate = useMemo(() => sub(today, { years: 10 }), [today])
	const maxDate = useMemo(() => add(today, { years: 1 }), [today])

	return (
		<div className="flex items-center space-x-3 justify-between">
			<IconHeader icon={<HiOutlineCalendar className="text-[#8E4EC6]" />} message="Events" />

			<Popover
				trigger={
					<Button
						auto
						ghost
						icon={<HiPlus />}
						disabled={!selectedDate || !selectedEventType}
						css={{ background: 'white', border: '0px' }}
					>
						{loading ? <Loading /> : 'Add'}
					</Button>
				}
				align="start"
				side="left"
			>
				<div className="flex flex-col w-[350px] space-y-3 p-3">
					<EventTypeSelect value={selectedEventType} onChange={setSelectedEventType} />

					<DateTimePicker
						selected={selectedDate ?? today}
						onChange={setSelectedDate}
						placement="left-start"
						minDate={minDate}
						maxDate={maxDate}
					/>
					<Button
						auto
						ghost
						onClick={handleAddEvent}
						disabled={!selectedDate || !selectedEventType}
						css={{ background: '#0091FF', border: '1px', color: 'white' }}
					>
						{loading ? <Loading /> : 'Create'}
					</Button>
				</div>
			</Popover>
		</div>
	)
}

type EventTypeSelectProps = {
	value?: string
	onChange?: (id: string) => void
}
function EventTypeSelect({ value, onChange }: EventTypeSelectProps) {
	const scheduledEventNames = useReaction(() => {
		const positionCandidate = rootStore.positions.getPositionCandidateById(
			store.activePositionCandidateId
		)
		return positionCandidate?.scheduledEvents?.map((e) => e.name) ?? []
	})

	const options = useReaction(() =>
		rootStore.candidates.settings.evenTypes.map((e) => ({ value: e.id, label: e.name }))
	)

	const finalOptions = options
		.filter((o) => !scheduledEventNames.includes(o.label))
		.sort((a, b) => a.label.localeCompare(b.label))
	return (
		<Select
			value={finalOptions.find((o) => o.value === value) ?? null}
			label={'Event type'}
			options={finalOptions}
			onChange={createSelectOnChange((v) => {
				onChange?.(v)
			})}
		/>
	)
}

function AttachmentsPanel() {
	const [uploadingFile, setUploadingFile] = useState(false)
	const [attachements, setAttachments] = useState<
		(IPositionCandidateAttachment | ICandidateAttachment)[]
	>([])
	const [latestResume, setLatestResume] = useState<ICandidateAttachment | undefined>(undefined)

	useEffect(() => {
		const positionCandidate = rootStore.positions.getPositionCandidateById(
			store.activePositionCandidateId
		)
		const positionId = positionCandidate?.positionId || ''
		const candidateId = positionCandidate?.candidateId || ''
		Promise.all([
			rootStore.positions.fetchPositionCandidateAttachments(positionId, candidateId),
			rootStore.candidates.resumes.getAllResumesForCandidate(candidateId),
		]).then(([positionAttachments = [], candidateAttachments = []]) => {
			setAttachments(
				[...positionAttachments, ...candidateAttachments].sort((a, b) => {
					return new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()
				})
			)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [store.activePositionCandidateId])

	useEffect(() => {
		setLatestResume(
			attachements.find((a) => a.type === 'resume') as ICandidateAttachment | undefined
		)
	}, [attachements])

	const deleteAttachment = async (
		attachment: IPositionCandidateAttachment | ICandidateAttachment
	) => {
		const pc = rootStore.positions.getPositionCandidateById(store.activePositionCandidateId)
		if (!pc) return
		rootStore.positions
			.deleteAttachmentToPositionCandidate(pc.candidateId, pc.positionId, attachment.id)
			.then((response) => {
				if (response?.success) {
					setAttachments((prev) => prev.filter((a) => a.id !== attachment.id))
					toast.success(`Attachment ${attachment.filename} deleted`)
				} else {
					toast.error('Error deleting attachment: ' + response?.message)
				}
			})
	}

	const columns = [
		{
			name: 'Name',
			cell: (row: IPositionCandidateAttachment | ICandidateAttachment) => (
				<div
					data-tag="allowRowEvents"
					className="flex flex-row justify-between w-full items-center"
				>
					<p data-tag="allowRowEvents" className="m-2 flex flex-row items-center">
						{row.filename}
						{row.id === latestResume?.id && (
							<HiStar className="h-5 w-5 text-amber-300 ml-2" />
						)}
					</p>

					{row.resourceType === 'position-candidate' && (
						<Tooltip content="Remove" placement="left" offset={-10}>
							<Button
								auto
								light
								color="error"
								icon={<HiTrash />}
								onClick={() => deleteAttachment(row)}
							/>
						</Tooltip>
					)}
				</div>
			),
		},
		{
			name: 'Creation Date',
			cell: (row: IPositionCandidateAttachment | ICandidateAttachment) => (
				<p className="m-2">{formatDate(row.creationDate)}</p>
			),
		},
	]

	const uploadFile = async (file: File) => {
		const pc = rootStore.positions.getPositionCandidateById(store.activePositionCandidateId)
		if (!pc) return

		const data = new FormData()
		data.append(`file`, file, file.name)

		const response = await rootStore.positions.addAttachmentToPositionCandidate(
			pc.candidateId,
			pc.positionId,
			data
		)
		if (response?.success && response?.doc) {
			const attachment = response.doc
			setAttachments((oldAttachments) => [...oldAttachments, attachment])
		} else {
			const description = response?.message ? `: ${response?.message}` : ''
			toast.error('Error uploading file' + description)
		}
	}
	const onDrop = async (acceptedFiles: File[]) => {
		setUploadingFile(true)
		for (const file of acceptedFiles) {
			await uploadFile(file)
		}
		setUploadingFile(false)
	}

	const onRowClicked = (row: IPositionCandidateAttachment | ICandidateAttachment) => {
		const url =
			row.resourceType === 'position-candidate'
				? positionCandidateAttachmentUrl(row.resourceId, row.id, row.filename)
				: candidateAttachmentUrl(row.resourceId, row.id, row.filename)
		window.open(url, '_blank', 'noreferrer')
	}

	return (
		<div>
			{' '}
			<div className="mb-12">
				{attachements.length <= 0 ? (
					<Text css={{ textAlign: 'center' }}>No Attachments yet</Text>
				) : (
					<Table columns={columns} rows={attachements} onRowClicked={onRowClicked} />
				)}
			</div>
			<Dropzone onDrop={onDrop} uploading={uploadingFile} />
		</div>
	)
}

function Similars({ positionCandidateId }: { positionCandidateId: string }) {
	const [loading, setLoading] = useState(false)
	const [positionCandidates, setPositionCandidates] = useState<IPositionCandidate[]>([])
	const [excludeSameAccount, setExcludeSameAccount] = useState(true)
	const [excludeSamePosition, setExcludeSamePosition] = useState(true)

	useEffect(() => {
		const findSimilars = async () => {
			setLoading(true)

			const positionCandidates = await rootStore.positions.findSimilarCandidates(
				positionCandidateId,
				excludeSameAccount,
				excludeSamePosition
			)

			setPositionCandidates(positionCandidates ?? [])
			setLoading(false)
		}

		findSimilars()
	}, [excludeSameAccount, excludeSamePosition, positionCandidateId])

	if (loading) {
		return (
			<div className="flex justify-center items-center w-full h-full overflow-x-hidden">
				<Loading size="lg" />
			</div>
		)
	}
	return (
		<div className="w-full flex flex-col space-y-3 items-center h-full">
			<div className="flex flex-col justify-start items-start w-full space-y-2">
				<Switch
					label={'Exclude same account'}
					checked={excludeSameAccount}
					onChange={(v) => setExcludeSameAccount(v)}
				/>
				<Switch
					label={'Exclude same position'}
					checked={excludeSamePosition}
					onChange={(v) => setExcludeSamePosition(v)}
				/>
			</div>
			{positionCandidates.length === 0 ? (
				<div>
					<span className="text-xl font-semibold">No similar candidates found</span>
				</div>
			) : (
				<BoardViewWrapper
					positionCandidates={positionCandidates}
					loadingCandidates={loading}
					onClick={(pc) => {
						if (pc) {
							window.open(`/accounts/${pc.accountId}/board/${pc.id}`, '_blank')
						}
					}}
				/>
			)}
		</div>
	)
}

type ResumeNotesProps = {
	positionCandidateId: string
}
function ResumeNotes({ positionCandidateId }: ResumeNotesProps) {
	const positionCandidate = useReaction(
		() => rootStore.positions.getPositionCandidateById(positionCandidateId),
		100,
		[positionCandidateId]
	)

	const notes = useReaction(
		() =>
			positionCandidate?.notes?.find(
				(n) => n.type === 'resume-notes'
			) as IPositionCandidateResumeNote,
		100,
		[positionCandidate]
	)

	if (!notes) {
		return (
			<div className="w-full flex justify-center p-10">
				<p className="text-base text-slate-500">Candidate has no resume notes</p>
			</div>
		)
	}

	return (
		<div className="w-full flex flex-col space-y-3">
			<ResumeNoteSection
				title="Years of Experience"
				content={notes.yearsOfExperience}
				emoji="🧐"
			/>
			<ResumeNoteSection
				title="Relevant Experience"
				content={notes.relevantExperience}
				emoji="🤓"
			/>
			<ResumeNoteSection title="Red Flags" content={notes.redFlags} emoji="🚩" />
			<ResumeNoteSection title="Short Tenure" content={notes.shortTenure} emoji="🩳" />
			<ResumeNoteSection title="Gaps" content={notes.gaps} emoji="⛔️" />
			<ResumeNoteSection title="Achievements" content={notes.achievements} emoji="🏆" />
			<ResumeNoteSection title="Certifications" content={notes.certifications} emoji="🎖️" />
			<ResumeNoteSection title="Education" content={notes.education} emoji="🎓" />
		</div>
	)
}

function ResumeNoteSection({
	title,
	content,
	emoji,
}: {
	title: string
	content?: string
	emoji?: string
}) {
	return (
		<div className="flex flex-col space-y-2 p-3 bg-slate-50 rounded-lg">
			<p className="text-base font-medium flex items-center">
				{emoji ? <span className="mr-2 text-lg">{emoji}</span> : null}
				{title}
			</p>
			<div className="prose dark:prose-invert !w-full !max-w-none">
				<ReactMarkdown>{content ?? 'None'}</ReactMarkdown>
			</div>
		</div>
	)
}

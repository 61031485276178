import { Loading } from '@nextui-org/react'
import { rootStore } from '@store'
import type { IPositionCandidate } from '@touchpoints/requests'

import { Dictionary, groupBy } from 'lodash'
import { nanoid } from 'nanoid'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { BoardColumn } from './BoardColumn'
import { store } from './store'
import { useBoardLayout } from './hooks'
import type { BoardEntry } from '@types'
import { BoardList } from './BoardList'

export interface BoardViewWrapperProps {
	positionCandidates: IPositionCandidate[]
	loadingCandidates?: boolean
	renderLoadingCandidates?: number
	onClick?: (positionCandidate: IPositionCandidate) => void
}

export function BoardViewWrapper({
	positionCandidates,
	loadingCandidates = false,
	renderLoadingCandidates,
	onClick,
}: BoardViewWrapperProps) {
	const router = useRouter()
	const params = useMemo(() => (router.query.params ?? []) as string[], [router])
	const positionCandidateId = useMemo(() => {
		if (params.length === 3) {
			// /accounts/:accountId/board/:positionCandidateId
			return params[2]
		}

		return undefined
	}, [params])
	const [positionId, candidateId] = useMemo(() => {
		if (params.length < 5) {
			return []
		}

		// /:accountId/board/:positionId/candidates/:candidateId
		return [params[2], params[4]]
	}, [params])

	useEffect(() => {
		const id = setTimeout(() => {
			if (!positionCandidateId && positionId && candidateId) {
				const pc = rootStore.positions.getPositionCandidate(candidateId, positionId)
				if (pc) {
					store.setSelectedPositionCandidateId(pc.id)
					return
				}

				rootStore.positions
					.fetchPositionCandidate(positionId, candidateId, {
						priority: 1,
					})
					.then((res) => {
						if (!res) {
							return
						}

						store.setSelectedPositionCandidateId(res.id)
					})
			} else if (positionCandidateId) {
				store.setSelectedPositionCandidateId(positionCandidateId)
			}
		}, 500)
		return () => clearTimeout(id)
	}, [positionCandidateId, positionId, candidateId])

	if (loadingCandidates && !renderLoadingCandidates) {
		return <Loading size="lg" className="mt-10" />
	}

	return (
		<div className="flex w-full h-full">
			<BoardView
				positionCandidates={positionCandidates}
				loadingCandidates={loadingCandidates}
				renderLoadingCandidates={renderLoadingCandidates}
				onClick={onClick}
			/>
		</div>
	)
}
export interface BoardViewProps {
	positionCandidates: IPositionCandidate[]
	loadingCandidates: boolean
	renderLoadingCandidates?: number
	onClick?: (positionCandidate: IPositionCandidate) => void
}

const fakeCandidate = (): BoardEntry => {
	return {
		id: nanoid(),
		loading: true,
	}
}

export function BoardView({
	positionCandidates,
	loadingCandidates,
	renderLoadingCandidates,
	onClick,
}: BoardViewProps) {
	const [boardEntriesByPosition, setBoardEntriesByPosition] = useState<
		Dictionary<BoardEntry[]> | undefined
	>(undefined)
	const [layout] = useBoardLayout()

	useEffect(() => {
		const entries = positionCandidates.map(positionCandidateToBoardEntry)
		const grouped = groupBy(entries, (e) => e.positionCandidate?.positionId)

		if (!renderLoadingCandidates || renderLoadingCandidates < 1) {
			setBoardEntriesByPosition(grouped)
			return
		}

		const keys = Object.keys(grouped)
		let counter = renderLoadingCandidates

		while (counter > 0 && keys.length > 0) {
			const index = counter % keys.length
			grouped[keys[index]].push(fakeCandidate())
			counter--
		}

		setBoardEntriesByPosition(grouped)
	}, [positionCandidates, renderLoadingCandidates])

	const sortedEntiresByPosition = useMemo(() => {
		if (!boardEntriesByPosition) {
			return []
		}

		return Object.keys(boardEntriesByPosition).sort((a, b) => {
			const aPosition = rootStore.positions.getPositionById(a)
			const bPosition = rootStore.positions.getPositionById(b)
			if (!aPosition || !bPosition) {
				return 0
			}
			return bPosition.referenceNumber - aPosition.referenceNumber
		})
	}, [boardEntriesByPosition])

	return (
		<div className="flex flex-col w-full h-full">
			{boardEntriesByPosition === undefined ? (
				<div className="flex w-full h-48 justify-center items-center">
					<p className="text-base text-slate-700">No positions</p>
				</div>
			) : layout === 'kanban' ? (
				<div className="w-full h-full overflow-x-auto scroll-pl-16 snap-x">
					<div className="flex w-full h-full">
						<div className="w-96 grid grid-flow-col gap-3">
							{sortedEntiresByPosition.map((positionId) => {
								return (
									<BoardColumn
										key={positionId}
										positionId={positionId}
										loadingCandidates={loadingCandidates}
										boardEntries={boardEntriesByPosition[positionId]}
										onClick={onClick}
									/>
								)
							})}
						</div>
					</div>
				</div>
			) : (
				<div className="flex flex-col w-full h-full">
					{sortedEntiresByPosition.map((positionId) => {
						return (
							<BoardList
								key={positionId}
								positionId={positionId}
								loadingCandidates={loadingCandidates}
								boardEntries={boardEntriesByPosition[positionId]}
								onClick={onClick}
							/>
						)
					})}
				</div>
			)}
		</div>
	)
}

export const positionCandidateToBoardEntry = (pc: IPositionCandidate): BoardEntry => {
	const recruiter = pc?.recruiterId
		? rootStore.organizationUsers.users[pc.recruiterId]
		: undefined

	const position = pc?.positionId ? rootStore.positions.getPositionById(pc.positionId) : undefined

	return {
		id: pc.id,
		positionCandidate: pc,
		candidate: pc.candidate,
		recruiter: recruiter,
		position: position,
	}
}

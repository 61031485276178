import { rootStore } from '@store'
import { useReaction } from '@touchpoints/mobx-hooks'
import type {
	ICandidate,
	IOrganizationUser,
	IPosition,
	IPositionCandidate,
} from '@touchpoints/requests'

import clsx from 'clsx'

import { memo, useRef } from 'react'

import { useIntersectionObserver } from 'usehooks-ts'
import { BoardCard } from './BoardCard'

import { store } from './store'
import { BoardHeader } from './BoardHeader'
import type { BoardEntry } from '@types'

export const positionCandidateToBoardEntry = (
	pc: IPositionCandidate
): {
	id: string
	positionCandidate: IPositionCandidate
	candidate?: ICandidate
	recruiter?: IOrganizationUser
	position?: IPosition
} => {
	const recruiter = pc?.recruiterId
		? rootStore.organizationUsers.users[pc.recruiterId]
		: undefined

	const position = pc?.positionId ? rootStore.positions.getPositionById(pc.positionId) : undefined

	const candidate = pc?.candidate
		? pc.candidate
		: rootStore.candidates.getCandidateById(pc.candidateId)

	return {
		id: pc.id,
		positionCandidate: pc,
		candidate: candidate,
		recruiter: recruiter,
		position: position,
	}
}

type BoardColumnsProps = {
	positionId: string
	boardEntries: BoardEntry[]
	loadingCandidates: boolean
	showAsCard?: boolean
	useIntersectionRenderer?: boolean
	intersectionThreshold?: number
	onClick?: (positionCandidate: IPositionCandidate) => void
}
export const BoardColumn = memo(function BoardColumn({
	positionId,
	boardEntries,
	loadingCandidates,
	showAsCard = true,
	useIntersectionRenderer = true,
	intersectionThreshold: threshold = 0,
	onClick,
}: BoardColumnsProps) {
	const ref = useRef<HTMLDivElement>(null)

	const entry = useIntersectionObserver(ref, { threshold })
	const position = useReaction(() => rootStore.positions.getPositionById(positionId), 100, [
		positionId,
	])

	if (!position) {
		console.log('no position', boardEntries)
		return null
	}

	if (boardEntries.length <= 0) {
		console.log('no board entries', boardEntries)
		return <></>
	}

	const sortByStageName = (a: BoardEntry, b: BoardEntry) => {
		if (!a.positionCandidate || !b.positionCandidate) return 0
		const aStageId = a.positionCandidate.stage
		const bStageId = b.positionCandidate.stage
		const aStage = aStageId ? rootStore.stages.candidatesStageById[aStageId]?.name ?? '' : ''
		const bStage = bStageId ? rootStore.stages.candidatesStageById[bStageId]?.name ?? '' : ''
		return aStage.localeCompare(bStage)
	}

	boardEntries.sort(sortByStageName)

	return (
		<div
			ref={ref}
			className={clsx('flex flex-col w-[300px] h-full mb-3 overflow-y-clip overflow-x-clip', {
				'p-2 space-y-3 hover:bg-slate-50/70': showAsCard,
				'w-[250px] border-r': !showAsCard,
				'animate-pulse': !!loadingCandidates,
			})}
		>
			<BoardHeader positionId={positionId} boardEntries={boardEntries} />
			<div className="flex flex-grow overflow-y-auto overflow-x-hidden">
				{useIntersectionRenderer && !!entry?.isIntersecting && (
					<div
						className={clsx('h-96 w-full flex flex-col', {
							'space-y-3': showAsCard,
						})}
					>
						{boardEntries.map((entry) => {
							if (entry.loading) {
								return <LoadingCandidate key={entry.id} />
							}
							if (!entry.positionCandidate) {
								return
							}
							return (
								<>
									<BoardCard
										key={entry.positionCandidate.id}
										positionCandidate={entry.positionCandidate}
										candidate={entry.candidate}
										recruiter={entry.recruiter}
										showAsCard={showAsCard}
										onClick={() => {
											if (onClick && entry.positionCandidate) {
												onClick(entry.positionCandidate)
												return
											}

											store.setSelectedPositionCandidateId(
												entry.positionCandidate?.id ?? ''
											)
											store.setBoardEntries(boardEntries)
										}}
									/>
								</>
							)
						})}
					</div>
				)}
				{!useIntersectionRenderer && (
					<div
						className={clsx('h-96 w-full flex flex-col', {
							'space-y-3': showAsCard,
						})}
					>
						{boardEntries.map((entry) => {
							if (entry.loading) {
								return <LoadingCandidate key={entry.id} />
							}

							if (!entry.positionCandidate) {
								return
							}
							return (
								<BoardCard
									key={entry.positionCandidate.id}
									positionCandidate={entry.positionCandidate}
									candidate={entry.candidate}
									recruiter={entry.recruiter}
									showAsCard={showAsCard}
									onClick={() => {
										store.setSelectedPositionCandidateId(
											entry.positionCandidate?.id ?? ''
										)
										store.setBoardEntries(boardEntries)
									}}
								/>
							)
						})}
					</div>
				)}
			</div>
		</div>
	)
})

const LoadingCandidate = memo(function LoadingCandidate() {
	return (
		<div className="p-3 rounded-md border shadow-sm max-w-sm w-full mx-auto">
			<div className="animate-pulse flex flex-col space-y-4">
				<div className="flex-1 space-y-6 py-1">
					<div className="h-2 bg-slate-300 rounded"></div>
					<div className="space-y-3">
						<div className="grid grid-cols-3 gap-4">
							<div className="h-2 bg-slate-300 rounded col-span-2"></div>
							<div className="h-2 bg-slate-300 rounded col-span-1"></div>
						</div>
						<div className="h-2 bg-slate-300 rounded"></div>
					</div>
				</div>
				<div className="flex justify-between">
					<div className="h-2 bg-slate-300 rounded col-span-2"></div>
					<div className="rounded-full bg-slate-300 h-5 w-5"></div>
				</div>
			</div>
		</div>
	)
})

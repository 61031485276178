import { NextUIProvider } from '@nextui-org/react'
import { SessionProvider, useSession } from 'next-auth/react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { PropsWithChildren, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import type { ReactElement, ReactNode } from 'react'

import { getMainLayout } from '@components/layouts'
import * as apiToken from '@services/apiToken'
import * as localData from '@services/localData'
import * as LR from '@services/logrocket'
import { rootStore } from '@store'
import { ConfirmDialogProvider, ToastContainer } from '@touchpoints/ui'

import '@fullcalendar/common/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import 'react-loading-skeleton/dist/skeleton.css'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-phone-number-input/style.css'
import 'react-sliding-pane/dist/react-sliding-pane.css'
import 'react-toastify/dist/ReactToastify.css'

import 'react-cmdk/dist/cmdk.css'
import 'reactflow/dist/style.css'

import '@nlux/themes/nova.css'

import { useLocalStorage } from 'usehooks-ts'
import '../styles/global.css'

type NextPageWithLayout = NextPage & {
	getLayout?: (page: ReactElement) => ReactNode
	getTitle?: () => string
}

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout
	pageProps: any
}

function ClientOnlyPortal({ children }: PropsWithChildren<any>) {
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		setMounted(true)
	}, [])

	return mounted ? createPortal(children, document.body) : null
}

function UserData() {
	const session = useSession()
	const router = useRouter()

	// LR.useLogRocket()

	useEffect(() => {
		const handler = async (evt: MessageEvent) => {
			const { type, id, status } = evt.data as { type: string; id: string; status?: string }
			if (type !== 'touchpoint-extension') {
				return
			}

			console.log(evt)

			if (!status) {
				console.error('extension message is malformed')
				return
			}

			switch (status) {
				case 'request-token':
					rootStore.extension.setId(id)
					router.push('/extension')
					break

				case 'complete':
					setTimeout(() => {
						router.back()
					}, 700)
					break
			}
		}
		window.addEventListener('message', handler)

		return () => {
			window.removeEventListener('message', handler)
		}
	}, [router])

	useEffect(() => {
		if (
			router.pathname === '/' ||
			router.pathname.startsWith('/auth') ||
			router.pathname.startsWith('/schedule') ||
			router.pathname.startsWith('/book') ||
			router.pathname.startsWith('/resumes') ||
			router.pathname.endsWith('/self-sourced')
		) {
			return
		}

		// send to login if not logged in
		if (session.status === 'unauthenticated') {
			router.push('/')
		}
	}, [session.status, router])

	useEffect(() => {
		if (session.status !== 'authenticated') {
			return
		}

		const fetchData = async () => {
			await rootStore.users.fetchLoggedInUser()

			const userId = rootStore.users.loggedInUserId
			const data = rootStore.users.loggedInUser
			if (userId && data) {
				LR.identify(userId, {
					firstName: data.firstName,
					lastName: data.lastName,
					email: data.email,
				})
			}

			const { currentOrgId } = localData.get()

			void rootStore.organizations.switchTo(currentOrgId)

			const token = apiToken.get()
			if (!token) {
				const session = await apiToken.getSession()
				if (session && session.accessToken) {
					apiToken.store(session.accessToken as string)
				}
			}
		}

		void fetchData()
	}, [session.status])

	return null
}

function useTooltipContainer() {
	useEffect(() => {
		const tooltipContainer = document.getElementById('nextui-tooltip')
		if (!tooltipContainer) {
			return
		}

		tooltipContainer.style.zIndex = '10000'
		tooltipContainer.style.position = 'absolute'
		tooltipContainer.style.pointerEvents = 'none'
		tooltipContainer.style.top = '0'
		tooltipContainer.style.left = '0'
		tooltipContainer.style.right = '0'
		tooltipContainer.style.bottom = '0'

		return () => {
			tooltipContainer.style.zIndex = ''
			tooltipContainer.style.position = 'static'
			tooltipContainer.style.pointerEvents = ''
			tooltipContainer.style.top = ''
			tooltipContainer.style.left = ''
			tooltipContainer.style.right = ''
			tooltipContainer.style.bottom = ''
		}
	}, [])
}

function CustomApp({ Component, pageProps }: AppPropsWithLayout) {
	const getLayout = Component.getLayout ?? getMainLayout

	useTooltipContainer()
	const [theme] = useLocalStorage<string>('theme', 'light')

	useEffect(() => {
		const mq = window.matchMedia('(prefers-color-scheme: dark)')
		const evaluateTheme = (theme: string, systemMatchesDark: boolean) => {
			if (theme === 'light') {
				document.documentElement.classList.remove('dark')
				return
			}

			if (theme === 'dark') {
				document.documentElement.classList.add('dark')
				return
			}

			if (systemMatchesDark) {
				document.documentElement.classList.add('dark')
			} else {
				document.documentElement.classList.remove('dark')
			}
		}

		evaluateTheme(theme, mq.matches)

		mq.addEventListener('change', (evt) => {
			evaluateTheme(theme, evt.matches)
		})
		return mq.removeEventListener('change', (evt) => {
			evaluateTheme(theme, evt.matches)
		})
	}, [theme])

	return (
		<SessionProvider session={pageProps.session}>
			<NextUIProvider disableBaseline>
				<ConfirmDialogProvider>
					<Head>
						<title>Welcome back to Touchpoints!</title>
						<link rel="shortcut icon" href="/favicon.ico" />
						<meta name="viewport" content="width=device-width, initial-scale=1" />
					</Head>
					<main style={{ height: '100%' }} suppressHydrationWarning={true}>
						<UserData />
						{getLayout(<Component {...pageProps} />)}
						<ClientOnlyPortal>
							<div style={{ zIndex: 99999, position: 'absolute' }}>
								<ToastContainer />
							</div>
						</ClientOnlyPortal>
					</main>
				</ConfirmDialogProvider>
			</NextUIProvider>
		</SessionProvider>
	)
}

export default CustomApp

import { authHeader, post } from './api'

import type { IAccount, IPosition, IPositionCandidate } from '@touchpoints/requests'

export async function findSimilarPositionCandidates(
	orgId: string,
	positionCandidateId: string,
	excludeSameAccount?: boolean,
	excludeSamePosition?: boolean
) {
	const headers = await authHeader()
	let url = `/organizations/${orgId}/experimental/embeddings/position-candidates/${positionCandidateId}/find-similars`

	if (excludeSameAccount) {
		url += '?excludeSameAccount=true'
	}

	if (excludeSamePosition) {
		url += excludeSameAccount ? '&excludeSamePosition=true' : '?excludeSamePosition=true'
	}

	const response = await post<{ positionCandidates: IPositionCandidate[] }>(
		url,
		undefined,
		headers
	)

	return response
}

export async function findSimilarPositions(orgId: string, positionId: string) {
	const headers = await authHeader()

	const response = await post<{ positions: IPosition[]; accountsById: Record<string, IAccount> }>(
		`/organizations/${orgId}/experimental/embeddings/positions/${positionId}/find-similars`,
		undefined,
		headers
	)

	return response
}
